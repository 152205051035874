// @import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

$header-lg-width: 30%;
$spacing-24: 3rem;
$spacing-12: 3rem;
$header-color: #45505b;
$color-blue: #0563bb;
$color-gray-background: #f7f8f9;

body {
  min-height: 100vh;
}

img, video {
  max-width: 100%;
  height: auto;
}

audio, canvas, embed, iframe, img, object, svg, video {
  display: block;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.section-subtitle {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #45505b;
}

ul.normal-list {
  padding-left: 14px;
  
  li {          
    list-style: none;
    position: relative;
    line-height: 1.5;
    margin-bottom: 10px;

    &:before {
      content: "\2219";
      // height: 6px;
      // width: 6px;
      // border-radius: 50%;
      // background: #000;
      font-size: 2.5rem;
      line-height: 0;
      position: absolute;
      top: 0.65rem;
      left: -1rem;
      // display: inline-block;
      // margin: 0 6px 0 0;
      // vertical-align: middle;
    }
  }

}

$skills-icon-name: skills-icon;

.#{$skills-icon-name}-bar {
  margin-bottom: 2.5rem;
  gap: 1rem;

  .#{$skills-icon-name} {
    height: 3rem;
    width: 3rem;
  }
}

.skill-text {
  padding: 10px;
  margin: 0;
  border-radius: 9999px;
  // text-transform: uppercase;
  display: inline-block;
  font-size: $progress-font-size;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #45505b;
  background-color: $color-gray-background;
}


.App {
  max-width: 1400px;

  #header {
    padding: 0;

    .header-info {

      &-name {
        margin: 0;
        font-size: 64px;
        font-weight: 700;
        line-height: 56px;
        color: $header-color;
      }
      
      &-intro {
        color: $header-color;
        // margin: 15px 0 0 0;
        font-size: 1rem;
        font-family: "Poppins", "Noto Sans Arabic", sans-serif;

        &:last-of-type {
          // margin-top: 0;
        }
      }

      .fa-icon {
        font-size: 20px;
      }
    }
  }
  
  #main {
    margin: 0;

    section {
      padding: 0;
      margin-bottom: 4rem;
  
      .section-title {
        h2 {
          font-size: 28px;
        }
      }
  
      .skills-subsection:last-of-type {
        margin-bottom: 0 !important;
      }
  
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .timeline {
    .timeline-title {
      font-size: 24px;
    }

    .timeline-item {
      padding-bottom: 2rem;

      &:last-child {
        padding-bottom: 1px;
      }
    }

    p {
      display: block;
    }
  }

  .social-links {
    margin-top: 30px;

    a {
      font-size: 24px;
      display: inline-block;
      color: $header-color;
      line-height: 1;
      margin-right: 20px;
      transition: 0.3s;

      &:hover {
        color: $color-blue;
      }
    }
  }
}

.typed-wrapper {
  text-align: left;
}

.typed-rtl {
  text-align: right;
  direction: rtl;
}

.projects {
  .icon-box {
    // text-align: center;
    padding: 0px;
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
    border: 1px solid transparent;
    border-radius: 4px;

    img {
      border-radius: 4px;
      // border: 1px solid transparent;
      transition: all ease-in-out 0.3s;
    }

    h4 {
      font-weight: 600;
      margin: 0;
      font-size: 22px;
      color: #45505b;
      // color: $color-blue;
      transition: ease-in-out 0.3s;
    }

    p {
      line-height: 24px;
      font-size: 14px;
      margin: 0;
    }

    &:hover {
      // border-color: #fff;
      box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
      // box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
      // background-color: #f7f8f9;
      
      img {
        // padding-left: 20px;
        border-color: #ddd;
      }

      .icon-box-info {
        // padding-right: 20px;
      }

      h4 {
        color: $color-blue;
      }

      .skill-wrapper span {
        color: $color-blue;
      }
    }

    .link-wrapper {
      a {
        display: inline-block;
        color: $header-color;
        line-height: 1;
        transition: 0.3s;
  
        &:hover {
          color: $color-blue;
        }

        svg {
          height: 1.5rem;
        }
      }
    }
  }

  & &-item {
    margin-bottom: 0;
  }
}

.contact {
  .info {
    background: inherit;

    > div:first-child {
      margin-top: 0;
    } 

    h4 {
      padding-left: 0;
    }

    p {
      padding-left: 0;
    }
  }

  .contact-email-form {
    background: inherit;
  }
}

@mixin nav-item-hover {
  .nav-text {
    color: #45505b;

    &::before {
      background-color: $color-blue;
      height: 3px;
    }
  }
}

@mixin nav-item-active {
  @include nav-item-hover;
  // color: #fff;
  // background: $color-blue;

  .nav-text {
    color: $color-blue;

    &::before {
      width: 4rem;
    }
  }
}

.nav-menu {
  padding: 0;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  >ul {
    width: max-content;

    >li {
      position: relative;
      white-space: nowrap;
    }
  }

  // li {
  //   &:hover {
  //     > a {
  //       @include nav-item-active;
  //     }
  //   }
  // }

  a {
    display: flex;
    align-items: center;
    color: #45505b;
    padding: 0.8rem 0;
    // margin-bottom: 8px;
    transition: all 0.3s;
    font-size: 15px;
    border-radius: 50px;
    // background: #f2f3f5;
    /* height: 56px; */
    /* width: 100%; */
    overflow: hidden;

    .nav-text {
      // padding: 0 5px 0 7px;
      color: #aaa;
      transition: all 0.3s;
      font-family: "Raleway", sans-serif;

      &::before {
        content: "";
        display: inline-block;
        margin-right: 0.5rem;
        height: 1px;
        width: 2rem;
        background-color: #ddd;
        vertical-align: middle;
        transition-property: background, width;
        transition-duration: 0.3s;
      }
    }

    &:hover {
      @include nav-item-hover;
    }
  }

  .active {
    &, &:focus {
      @include nav-item-active;
    }
  }
}

@include media-breakpoint-down(sm) {
  .timeline {
    .timeline-item {
      &::before {
        display: none;
      }
  
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .form-group-row {
    gap: 10px;
  }

  .link-wrapper a svg {
    height: 1.2rem !important;
  }
}

@include media-breakpoint-up(lg) {
  .App {
    #header {
      max-height: 100vh;
      width: $header-lg-width;
      margin-right: 2rem;
      position: sticky;
      top: 0;
    }
    
    #main {
      width: calc(100% - $header-lg-width);
      
      section {
        margin-bottom: $spacing-24;
      }
    }
  }

  .projects .icon-box {
    padding: 32px 16px;

    &:hover {
      border-color: #ddd;
    }
  }
}